import React  from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter as Router,  Switch } from "react-router-dom";
// import { hashHistory } from 'react-router'
import renderRoutes from './until/renderRouters'
import routes from './router'
import store from "./store"
import "./style.js"
import "./App.css"
import VConsole from 'vconsole/dist/vconsole.min.js' //import vconsole

if(process.env.NODE_ENV==='development'){
    let vConsole = new VConsole()
}
 
const supportsHistory = 'pushState' in window.history;
ReactDOM.render((
    <Provider store={store} >
    <Router forceRefresh={!supportsHistory}>
    <Switch>
         {renderRoutes(routes)}
    </Switch>
    </Router >
</Provider>
),
document.getElementById('root'));










// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
