import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import storage from './storage';
import config from './../config/index';
function getUrlParms(name){
    var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if(r!=null)
    return unescape(r[2]);
    return null;
    }
const renderRoutes = (routes, authPath = '/login', extraProps = {}, switchProps = {}) => routes ? (

    <Switch {...switchProps}>
        {routes.map((route, i) => (
            <Route
                key={route.key || i}
                path={route.path}
                exact={route.exact}
                strict={route.strict}
                render={(props) => {
                    //  const authed   =  sessionStorage.getItem("token");
                    //   console.log('切换路由了')
                    //   console.log(route.requiresAuth, authed, route.path === authPath)
                    //   console.log(!route.requiresAuth || authed || route.path === authPath)
                    // if (!route.requiresAuth || authed || route.path === authPath) {
                    //   return <route.component {...props} {...extraProps} route={route} />
                    // }
                    // return <Redirect to={{ pathname: authPath, state: { from: props.location } }} />
                    let token = storage.getToken();
                 
                   let openid = getUrlParms('openid');
                   if(openid&&openid.length>0){
                    storage.setCookie('openid',openid)
                   }
                    var _hmt = _hmt || [];
                    (function () {
                        document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
                        var hm = document.createElement("script");
                        hm.src = config.BaiduSrc;
                        hm.id ="baidu_tj";
                       // console.log(config.BaiduSrc)
                        var s = document.getElementsByTagName("script")[0];
                        s.parentNode.insertBefore(hm, s);
                    })();
                  
                    if (token || !route.requiresAuth || route.path === authPath) {
                        return <route.component {...props} {...extraProps} route={route} meta={route.meta} />
                    }
                    return <Redirect to={{ pathname: authPath, state: { from: props.location } }} />
                }}
            />
        ))}
    </Switch>
) : null

export default renderRoutes