import { createStore,applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import reducer from "./reducer"
import  axios from "../api/axios"
const store = createStore(reducer, compose(
    applyMiddleware(thunk.withExtraArgument(axios)),
    window.devToolsExtension ? window.devToolsExtension() : f => f
    )
    );

export default store;