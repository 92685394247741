class storage {
    /**
     * 设置cookie
     * @param {*} name   cookie 指定的名字 key
     * @param {*} value  cookie 指定的值  value
     * @param {*} Days   设置有效的时间
     */
    static setCookie(name, value, Days = 7) {

        var exp = new Date();
        exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
        // document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
        document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString()+";path=/"
    }

    /**
     * 获取cookie
     * @param {*} name 
     */
    static getCookie(name) {
        var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        arr = document.cookie.match(reg)
        if (arr )
            return unescape(arr[2]);
        else
            return null;
    }

    /**
     *  删除指定名字的cookie
     * @param {*} name 
     */
    static delCookie(name) {
        var exp = new Date();
        exp.setTime(exp.getTime() - 1);
        var cval = storage.getCookie(name);
        if (cval != null)
            document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString()+";path=/";
    }
    /**
     * 删除所有的cookie
     */
    static clearCookie() {
        var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
        if (keys) {
            for (var i = keys.length; i--;) {
                document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
                document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();
                document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的，例如 .m.kevis.com
                // document.cookie = keys[i] + '=0;path=/;domain=kevis.com;expires=' + new Date(0).toUTCString();//清除一级域名下的或指定的，例如 .kevis.com
            }
        }
        
    }


    static getToken()
    {
     return  this.getCookie('token')
    }


    static setToken(token)
    {
        this.setCookie('token',token)
    }

}


export default storage;